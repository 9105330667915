<template>
  <b-modal
    v-model="modal"
    size="lg"
    :title="title"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    header-class="text-capitalize"
    body-class="max-h-400px project-role-management-modal"
  >
    <!-- begin:: Modal Body -->
    <!-- begin:: Form input -->
    <b-form-group class="mb-0">
      <div class="mb-6">
        <AppFormField
          v-model="$v.projectRoleObj.name.$model"
          :state="validateObjState('projectRoleObj', 'name')"
          :validationValue="$v.projectRoleObj.name"
          :title="$t('label.name')"
          :validators="['required']"
          :disabled="isFormDisabled"
          solid
        ></AppFormField>
      </div>
      <div class="mb-6">
        <AppFormField
          v-model="$v.projectRoleObj.projectTypeIds.$model"
          :state="validateObjState('projectRoleObj', 'projectTypeIds')"
          :validationValue="$v.projectRoleObj.projectTypeIds"
          :validators="['required']"
          :title="$t('label.projectTypes')"
          :disabled="isFormDisabled"
          formType="multiselect"
          :options="projectTypesOptions"
          :config="{
            labelKey: 'name',
            valueKey: 'id',
            closeOnSelect: false
          }"
          solid
        ></AppFormField>
      </div>
    </b-form-group>
    <!-- end:: Form Input -->

    <!-- begin:: Is Deleted Field -->
    <div
      v-if="!isNewRecord"
      class="project-role-management-modal__is-deleted-field-container"
    >
      <label class="font-size-sm">
        {{ $t('label.isDeleted') }}
      </label>
      <AppCheckCircle v-model="projectRoleObj.isDeleted"></AppCheckCircle>
    </div>
    <!-- end:: Is Deleted Field -->

    <!-- end:: Modal Body -->

    <!-- begin:: Modal Footer -->
    <template #modal-footer>
      <b-button
        v-if="isShowDiscardButton"
        class="mr-2"
        variant="white"
        @click="onClickDiscard"
        >{{ $t('label.discard') }}</b-button
      >
      <b-button
        v-if="isShowSaveChangesButton"
        variant="primary"
        @click="onClickSaveChanges"
        >{{ $t('label.saveChanges') }}</b-button
      >
      <b-button
        v-if="isShowSubmitButton"
        variant="success"
        @click="onClickSubmit"
        >{{ $t('label.submit') }}</b-button
      >
      <b-button
        v-if="isShowCloseButton"
        variant="primary"
        @click="onClickClose"
        >{{ $t('label.close') }}</b-button
      >
    </template>
    <!-- end:: Modal Footer -->
  </b-modal>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import AppCheckCircle from '@/views/components/bases/AppCheckCircle';
  import commonMixin from '@/core/mixins/common.mixin';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import { i18nHelper, uiHelper } from '@/core/utils';
  import {
    PROJECT_ROLE_GET_PROJECT_ROLE,
    PROJECT_ROLE_CREATE_PROJECT_ROLE,
    PROJECT_ROLE_UPDATE_PROJECT_ROLE,
    PROJECT_ROLE_INITIAL_PROJECT_ROLE_STATE,
    PROJECT_ROLE_INITIAL_CREATE_PROJECT_ROLE_STATE,
    PROJECT_ROLE_INITIAL_UPDATE_PROJECT_ROLE_STATE
  } from '@/core/store/project-roles.module';
  import {
    LOOKUP_GET_PROJECT_TYPES,
    LOOKUP_INITIAL_PROJECT_TYPES_STATE
  } from '@/core/store/lookup.module';

  export default {
    name: 'ProjectRoleManagementModal',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField,
      AppCheckCircle
    },
    props: {
      value: {
        type: Boolean,
        default: false,
        required: true
      },
      id: {
        type: Number,
        default: 0,
        required: true
      },
      isNewRecord: {
        type: Boolean,
        default: false,
        required: true
      }
    },
    data: () => ({
      modal: false,
      currentModule: MODULE.PROJECT_ROLE,
      projectRoleObj: {
        name: null,
        projectTypeIds: [],
        isDeleted: false
      },
      formFields: [
        {
          model: 'name',
          title: 'name',
          validators: ['required']
        },
        {
          model: 'projectTypeIds',
          title: 'projectTypes',
          validators: ['required']
        },
      ],
      projectTypesOptions: []
    }),
    computed: {
      projectRoleComplete() {
        return this.$store.state.projectRoles.projectRole.complete;
      },
      createProjectRoleComplete() {
        return this.$store.state.projectRoles.createProjectRole.complete;
      },
      updateProjectRoleComplete() {
        return this.$store.state.projectRoles.updateProjectRole.complete;
      },
      getProjectTypesComplete() {
        return this.$store.state.lookup.projectTypes.complete;
      },
      isFormDisabled() {
        var moduleName = this.currentModule;
        var hasAccessRight = (module, actions) => {
          return this.checkAccessRight(module, actions);
        };

        return this.isNewRecord
          ? !hasAccessRight(moduleName, [ACTION.PROJECT_ROLE_CREATE])
          : !hasAccessRight(moduleName, [ACTION.PROJECT_ROLE_UPDATE]);
      },
      isShowSubmitButton() {
        return (
          this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.PROJECT_ROLE_CREATE
          ])
        );
      },
      isShowDiscardButton() {
        return this.isNewRecord
          ? true
          : this.checkAccessRight(this.currentModule, [
              ACTION.PROJECT_ROLE_UPDATE
            ]);
      },
      isShowCloseButton() {
        return this.isNewRecord
          ? false
          : !this.checkAccessRight(this.currentModule, [
              ACTION.PROJECT_ROLE_UPDATE
            ]);
      },
      title() {
        return this.isNewRecord
          ? i18nHelper.getMessage('label.newProjectRole')
          : i18nHelper.getMessage('label.projectRoleDetails');
      },
      isShowSaveChangesButton() {
        return (
          !this.isNewRecord &&
          this.checkAccessRight(this.currentModule, [
            ACTION.PROJECT_ROLE_UPDATE
          ])
        );
      }
    },
    watch: {
      value() {
        if (this.value) {
          if (this.isNewRecord) {
            this.modal = true;
          } else {
            this.getProjectRole(this.id);
          }
        }
      },
      modal() {
        this.modal
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      },
      projectRoleComplete() {
        let response = this.$store.state.projectRoles.projectRole;
        let title = i18nHelper.getMessage('label.getProjectRole');
        let initialStateAction = PROJECT_ROLE_INITIAL_PROJECT_ROLE_STATE;
        let successAction = (response) => {
          this.getProjectRoleCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      createProjectRoleComplete() {
        var response = this.$store.state.projectRoles.createProjectRole;
        var title = i18nHelper.getMessage('label.createNewProjectRole');
        var initialStateAction = PROJECT_ROLE_INITIAL_CREATE_PROJECT_ROLE_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionCreateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      updateProjectRoleComplete() {
        var response = this.$store.state.projectRoles.updateProjectRole;
        var title = i18nHelper.getMessage('label.updateProjectRole');
        var initialStateAction = PROJECT_ROLE_INITIAL_UPDATE_PROJECT_ROLE_STATE;
        var successAction = () => {
          this.closeModalAction();
          this.initialList();
        };

        if (response.complete) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      getProjectTypesComplete() {
        var response = this.$store.state.lookup.projectTypes;
        var title = i18nHelper.getMessage('label.getProjectTypes');
        var initialStateAction = LOOKUP_INITIAL_PROJECT_TYPES_STATE;
        var successAction = () => {
          this.getProjectTypesCompleteActions(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
    },
    methods: {
      onClickSaveChanges() {
        var data = {
          ...this.projectRoleObj,
          projectTypeIds: this.projectRoleObj.projectTypeIds.map((x) => x.id)
        };
        var id = this.id;

        if (this.validationCheck('projectRoleObj')) {
          this.updateProjectRole(id, data);
        }
      },
      onClickDiscard() {
        this.closeModalAction();
      },
      onClickSubmit() {
        var data = {
          ...this.projectRoleObj,
          projectTypeIds: this.projectRoleObj.projectTypeIds.map((x) => x.id)
        };

        if (this.validationCheck('projectRoleObj')) {
          this.createProjectRole(data);
        }
      },
      initialList() {
        this.$parent.$refs.appSmartList.initAppSmartList();
      },
      getProjectRoleCompleteActions(response) {
        this.projectRoleObj = response.data;
        this.modal = true;
        this.$v.$reset();
      },
      getProjectTypesCompleteActions(response) {
        this.projectTypesOptions = response.data;
      },
      onClickClose() {
        this.closeModalAction();
      },
      resetForm() {
        this.projectRoleObj = {
          name: null,
          projectTypeIds: [],
          isDeleted: false
        };

        this.$v.$reset();
      },
      closeModalAction() {
        this.resetForm();
        this.input(false);
        this.modal = false;
      },
      input(value) {
        this.$emit('input', value);
      },
      updateProjectRole(id, data) {
        this.$store.dispatch(PROJECT_ROLE_UPDATE_PROJECT_ROLE, { id, data });
      },
      createProjectRole(data) {
        this.$store.dispatch(PROJECT_ROLE_CREATE_PROJECT_ROLE, { data });
      },
      getProjectRole(id) {
        this.$store.dispatch(PROJECT_ROLE_GET_PROJECT_ROLE, {
          id
        });
      },
      getProjectTypes() {
        this.$store.dispatch(LOOKUP_GET_PROJECT_TYPES);
      },
    },
    mounted() {
      this.getProjectTypes();
    },
    validations: {
      projectRoleObj: {
        name: {
          required
        },
        projectTypeIds: {
          required
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
  .project-role-management-modal {
    min-height: 300px;
  }
</style>
