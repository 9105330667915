<template>
  <div>
    <AppSmartList
      ref="appSmartList"
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :newRecord="newRecord"
      @app-smart-list-action-new-record="newRecordAction"
      @app-smart-list-open-modal-details="openModalDetailsAction"
    ></AppSmartList>

    <!--begin:: App Update Details Modal-->
    <ProjectRoleManagementModal
      v-model="projectRoleDetailsModal.isShow"
      :id="projectRoleDetailsModal.id"
      :isNewRecord="projectRoleDetailsModal.isNewRecord"
    ></ProjectRoleManagementModal>
    <!--end:: App Update Details Modal-->
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList';
  import ProjectRoleManagementModal from '@/views/components/talent-category/project-role/ProjectRoleManagementModal';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    PROJECT_ROLES_GET_PROJECT_ROLES,
    PROJECT_ROLE_DELETE_PROJECT_ROLE,
    PROJECT_ROLES_INITIAL_PROJECT_ROLES_STATE,
    PROJECT_ROLE_INITIAL_DELETE_PROJECT_ROLE_STATE
  } from '@/core/store/project-roles.module';

  export default {
    name: 'ProjectRoleList',
    components: {
      AppSmartList,
      ProjectRoleManagementModal
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.talentCategory'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY }
          },
          {
            title: i18nHelper.getMessage('label.projectRole'),
            route: { name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE }
          },
          { title: i18nHelper.getMessage('label.projectRoleList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.PROJECT_ROLE,
        actions: {
          create: ACTION.PROJECT_ROLE_CREATE,
          read: ACTION.PROJECT_ROLE_READ,
          update: ACTION.PROJECT_ROLE_UPDATE,
          delete: ACTION.PROJECT_ROLE_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        }
      ],
      actions: {
        viewList: {
          module: 'projectRoles',
          state: 'projectRoles',
          action: PROJECT_ROLES_GET_PROJECT_ROLES,
          initialStateAction: PROJECT_ROLES_INITIAL_PROJECT_ROLES_STATE,
          title: i18nHelper.getMessage('label.getProjectRoles')
        },
        deleteRecord: {
          module: 'projectRoles',
          state: 'deleteProjectRole',
          action: PROJECT_ROLE_DELETE_PROJECT_ROLE,
          initialAction: PROJECT_ROLE_INITIAL_DELETE_PROJECT_ROLE_STATE,
          title: i18nHelper.getMessage('label.deleteProjectRole'),
          message: i18nHelper.getMessage('message.requestDeleteMessage', [
            i18nHelper.getMessage('label.projectRole').toLowerCase()
          ]),
          successAction: () => {}
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'isDeleted',
          name: 'isDeleted',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_CHECK_CIRCLE
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS,
          layoutActions: {
            isModal: true,
            hasActionDelete: true
          }
        }
      ],
      projectRoleDetailsModal: {
        isShow: false,
        id: 0,
        isNewRecord: false
      },
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newProjectRole'),
        isModal: true
      }
    }),
    created() {
      this.initProjectRoleList();
    },
    methods: {
      importSuccessAction() {
        this.actions.deleteRecord.successAction = () => {
          this.$refs.appSmartList.initAppSmartList();
        };
      },
      newRecordAction() {
        this.openProjectRoleManagementModal(true);
      },
      openModalDetailsAction(value) {
        this.openProjectRoleManagementModal(false, value);
      },
      openProjectRoleManagementModal(isNewRecord, id) {
        this.projectRoleDetailsModal = {
          isShow: true,
          id: id,
          isNewRecord: isNewRecord
        };
      },
      initProjectRoleList() {
        this.importSuccessAction();
      }
    }
  };
</script>

<style></style>
